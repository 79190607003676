<!-- components/DoctorScheduleSettings.vue -->
<template>
    <div class="settings-container">
      <div class="settings-section">
        <h3>Genel Ayarlar</h3>
        <div class="setting-group">
          <label>
            Görüşme Süresi (dakika)
            <input 
              type="number" 
              v-model.number="settings.sessionDuration" 
              min="15" 
              step="5"
            >
          </label>
          <label>
            Randevular Arası Mola (dakika)
            <input 
              type="number" 
              v-model.number="settings.breakDuration" 
              min="0" 
              step="5"
            >
          </label>
          <label>
            Kaç Ay İlerisi İçin Randevu Alınabilir
            <input 
              type="number" 
              v-model.number="settings.monthLater" 
              min="0" 
              step="5"
            >
          </label>
        </div>
      </div>
  
      <div class="settings-section">
        <h3>Haftalık Çalışma Programı</h3>
        <div class="weekly-schedule">
          <div 
            v-for="(day, index) in weekDays" 
            :key="day"
            class="day-settings"
          >
            <div class="day-header">
              <label class="day-toggle">
                <input 
                  type="checkbox" 
                  v-model="settings.workingDays[index].isWorking"
                >
                {{ day }}
              </label>
            </div>
            
            <div v-if="settings.workingDays[index].isWorking" class="time-settings">
              <div class="time-slot">
                <label>Başlangıç:
                  <input 
                    type="time" 
                    v-model="settings.workingDays[index].startTime"
                    @change="validateTimeSlots(index)"
                  >
                </label>
                <label>Bitiş:
                  <input 
                    type="time" 
                    v-model="settings.workingDays[index].endTime"
                    @change="validateTimeSlots(index)"
                  >
                </label>
              </div>
              
              <div class="break-settings">
                <label>
                  <input 
                    type="checkbox" 
                    v-model="settings.workingDays[index].hasLunchBreak"
                  >
                  Öğle Molası
                </label>
                <div v-if="settings.workingDays[index].hasLunchBreak" class="lunch-break">
                  <input 
                    type="time" 
                    v-model="settings.workingDays[index].lunchBreakStart"
                  >
                  -
                  <input 
                    type="time" 
                    v-model="settings.workingDays[index].lunchBreakEnd"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="settings-section">
        <h3>Özel İzin Günleri</h3>
        <div class="off-days">
          <div class="add-off-time">
            <input 
              type="date" 
              v-model="newOffDay.date"
              :min="today"
            >
            <select v-model="newOffDay.type">
              <option value="full">Tam Gün</option>
              <option value="partial">Belirli Saatler</option>
            </select>
            <template v-if="newOffDay.type === 'partial'">
              <input type="time" v-model="newOffDay.startTime">
              <input type="time" v-model="newOffDay.endTime">
            </template>
            <button @click="addOffDay">Ekle</button>
          </div>
  
          <div class="off-days-list">
            <div 
              v-for="(offDay, index) in settings.offDays" 
              :key="index"
              class="off-day-item"
            >
              <span>{{ formatOffDay(offDay) }}</span>
              <button @click="removeOffDay(index)" class="remove-btn">×</button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="settings-actions">
        <button @click="saveSettings" class="save-btn">Kaydet</button>
        <button @click="resetSettings" class="reset-btn">Varsayılana Dön</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DoctorScheduleSettings',
    data() {
      return {
        weekDays: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
        today: new Date().toISOString().split('T')[0],
        newOffDay: {
          date: '',
          type: 'full',
          startTime: '',
          endTime: ''
        },
        settings: null
      }
    },
    methods: {
      validateTimeSlots(dayIndex) {
        const day = this.settings.workingDays[dayIndex]
        if (day.startTime >= day.endTime) {
          day.endTime = this.addMinutes(day.startTime, 60)
        }
        if (day.hasLunchBreak) {
          if (day.lunchBreakStart <= day.startTime) {
            day.lunchBreakStart = this.addMinutes(day.startTime, 60)
          }
          if (day.lunchBreakEnd >= day.endTime) {
            day.lunchBreakEnd = this.addMinutes(day.endTime, -60)
          }
        }
      },
      addMinutes(time, minutes) {
        const [hours, mins] = time.split(':').map(Number)
        const date = new Date(2000, 0, 1, hours, mins)
        date.setMinutes(date.getMinutes() + minutes)
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
      },
      formatOffDay(offDay) {
        const date = new Date(offDay.date).toLocaleDateString('tr-TR')
        if (offDay.type === 'full') {
          return `${date} - Tam Gün`
        }
        return `${date} - ${offDay.startTime} - ${offDay.endTime}`
      },
      addOffDay() {
        if (!this.newOffDay.date) return
  
        this.settings.offDays.push({
          date: this.newOffDay.date,
          type: this.newOffDay.type,
          startTime: this.newOffDay.type === 'partial' ? this.newOffDay.startTime : '',
          endTime: this.newOffDay.type === 'partial' ? this.newOffDay.endTime : ''
        })
  
        // Reset form
        this.newOffDay = {
          date: '',
          type: 'full',
          startTime: '',
          endTime: ''
        }
        
        // Ayarları store'a kaydet
        this.$store.commit('updateDoctorSettings', this.settings)
      },
      removeOffDay(index) {
        this.settings.offDays.splice(index, 1)
        this.$store.commit('updateDoctorSettings', this.settings)
      },
      saveSettings() {
        this.$store.commit('updateDoctorSettings', this.settings)
      },
      resetSettings() {
        this.settings = JSON.parse(JSON.stringify(this.defaultSettings))
        this.$store.commit('updateDoctorSettings', this.settings)
      }
    },
    created() {
      // Store'dan ayarları yükle
      const storedSettings = this.$store.getters.doctorSettings
      console.log(storedSettings)
      if (storedSettings) {
        this.settings = storedSettings
      }
    }
  }
  </script>
  
  <style scoped>
  .settings-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .settings-section {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .setting-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    margin-top: 15px;
     & > label {
      display:flex;
      flex-direction: column;
      gap: 8px;
     }
  }
  
  .weekly-schedule {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 15px;
  }
  
  .day-settings {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 6px;
  }
  
  .time-settings {
    margin-top: 10px;
  }
  
  .time-slot {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .break-settings {
    margin-top: 15px;
  }
  
  .lunch-break {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .off-days {
    margin-top: 15px;
  }
  
  .add-off-time {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .off-days-list {
    display: grid;
    gap: 10px;
  }
  
  .off-day-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .remove-btn {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .settings-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .save-btn, .reset-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .reset-btn {
    background-color: #f44336;
    color: white;
  }
  
  input[type="time"],
  input[type="number"],
  input[type="date"],
  select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  </style>