<template>
  <div class="address-info">
    <el-form :model="doctor" label-width="120px">
      <div class="form-container">
        <div class="form-group">
          <div class="form-row">
            <div class="input-group">
              <div class="text">Ülke <span class="required">*</span></div>
              <el-input v-model="doctor.LivingAddress.Country" placeholder="Ülke"></el-input>
            </div>

            <div class="input-group">
              <div class="text">İl <span class="required">*</span></div>
              <el-input v-model="doctor.LivingAddress.City" placeholder="İl"></el-input>
            </div>
          </div>

          <div class="form-row">
            <div class="input-group">
              <div class="text">İlçe <span class="required">*</span></div>
              <el-input v-model="doctor.LivingAddress.District" placeholder="İlçe"></el-input>
            </div>

            <div class="input-group">
              <div class="text">Posta Kodu</div>
              <el-input v-model="doctor.LivingAddress.ZipCode" placeholder="Posta Kodu"></el-input>
            </div>
          </div>

          <div class="input-group">
            <div class="text">Açık Adres <span class="required">*</span></div>
            <el-input 
              type="textarea" 
              v-model="doctor.LivingAddress.Address" 
              :rows="4"
              placeholder="Açık adres giriniz...">
            </el-input>
          </div>
        </div>
      </div>

      <div class="form-actions">
        <el-button type="primary" icon="icon-send" @click="$emit('save')" class="save-button">
          Kaydet
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AddressInformation',
  props: {
    doctor: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.address-info {
  padding: 20px;

 

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    border-radius: 10px;
  }

  .form-row {
    display: flex;
    gap: 20px;

    .input-group {
      flex: 1;
    }
  }

  .input-group {
    .text {
      font-size: 14px;
      color: #8697a8;
      margin-bottom: 8px;

      .required {
        color: #ff4949;
      }
    }
  }

  :deep(.el-input__inner) {
    background-color: #f8fafb;
    border: 1px solid #eaedf0;
    height: 40px;
    
    &:focus {
      border-color: #234a98;
    }
  }

  :deep(.el-textarea__inner) {
    background-color: #f8fafb;
    border: 1px solid #eaedf0;
    
    &:focus {
      border-color: #234a98;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding: 0 30px;

    .save-button {
      background-color: #234a98;
      border: none;
      padding: 12px 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 140px;
      justify-content: center;
      max-width: 200px;
      
      i {
        font-size: 16px;
      }

      &:hover {
        background-color: darken(#234a98, 5%);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .form-row {
      flex-direction: column;
      gap: 20px;
    }

    .form-group {
      padding: 20px;
    }

    .form-actions {
      padding: 0 20px;
    }
  }
}
</style> 