<template>
  <div class="personal-info">
    <el-form :model="doctor" label-width="120px">
      <div class="form-container">
        <div class="left-section">
          <div class="image-upload">
            <input type="file" @change="uploadFile" style="display: none" ref="file" />
            <div class="image" @click="$refs.file.click()" :style="{ backgroundImage: 'url(' + doctor.Image + ')' }">
              <i class="icon-camera-2"></i>
              <div>Fotoğraf Yükle</div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-row">
              <div class="input-group">
                <div class="text">Doktor Adı <span class="required">*</span></div>
                <el-input v-model="doctor.FirstName" placeholder="Ad"></el-input>
              </div>

              <div class="input-group">
                <div class="text">Soyadı <span class="required">*</span></div>
                <el-input v-model="doctor.LastName" placeholder="Soyad"></el-input>
              </div>
            </div>

            <div class="form-row">
              <div class="input-group">
                <div class="text">TC No <span class="required">*</span></div>
                <el-input-number 
                  :controls="false" 
                  v-model="doctor.IdentityNumber"
                  placeholder="TC Kimlik No"
                  class="full-width">
                </el-input-number>
              </div>

              <div class="input-group">
                <div class="text">E-Posta <span class="required">*</span></div>
                <el-input v-model="doctor.Email" placeholder="E-posta"></el-input>
              </div>
            </div>

            <div class="input-group full">
              <div class="text">Cep Telefonu <span class="required">*</span></div>
              <vue-phone-number-input
                v-model="doctor.PhoneNumber"
                @update="$emit('phone-update', $event)"
                :no-example="true"
                ref="phoneInput"
                :translations="{
                  countrySelectorLabel: $localization('web-login-country-code'),
                  countrySelectorError: $localization('web-login-select-country-code'),
                  phoneNumberLabel: $localization('web-login-phone-number'),
                  example: $localization('web-login-example'),
                }"
                size="lg"
                default-country-code="TR" />
            </div>
          </div>
        </div>

        <div class="right-section">
          <div class="form-group">
            <div class="form-row">
              <div class="input-group">
                <div class="text">Ünvan <span class="required">*</span></div>
                <el-input v-model="doctor.Specialist" placeholder="Ünvan"></el-input>
              </div>

              <div class="input-group">
                <div class="text">Cinsiyet</div>
                <el-select placeholder="Seçiniz..." v-model="doctor.Gender" class="full-width">
                  <el-option 
                    v-for="(item, index) in genders" 
                    :key="index"
                    :label="item.Key" 
                    :value="item.Value">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="input-group">
              <div class="text">Branş <span class="required">*</span></div>
              <el-select 
                v-model="doctor.SpecialityIds" 
                placeholder="Branş Seçiniz..." 
                filterable 
                multiple
                class="full-width">
                <el-option 
                  v-for="item in specialities" 
                  :key="item.ID" 
                  :label="item.Title" 
                  :value="item.ID">
                </el-option>
              </el-select>
            </div>

            <div class="input-group">
              <div class="text">Doğum Tarihi</div>
              <el-date-picker 
                v-model="doctor.BirthDate" 
                format="dd.MM.yyyy" 
                type="date" 
                placeholder="Doğum Tarihi Seçiniz..." 
                default-value="1980-01-01" 
                class="full-width">
              </el-date-picker>
            </div>

            <div class="input-group">
              <div class="text">Hakkında</div>
              <el-input 
                type="textarea" 
                v-model="doctor.Biography"
                :rows="4"
                placeholder="Doktor hakkında bilgi...">
              </el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="form-actions">
        <el-button type="primary" icon="icon-send" @click="$emit('save')" class="save-button">
          Kaydet
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'PersonalInformation',
  props: {
    doctor: {
      type: Object,
      required: true
    },
    specialities: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      genders: [
        { Key: "Kadın", Value: 1 },
        { Key: "Erkek", Value: 2 }
      ]
    }
  },
  methods: {
    async uploadFile() {
      let file = this.$refs.file.files[0];
      var fd = new FormData();
      fd.append("file", file);
      
      try {
        var res = await this.$client.post("/Account/UploadFile?type=3", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.doctor.Image = res.data.Item.Url;
          this.$message.success("Fotoğraf başarıyla yüklendi");
        }
      } catch (error) {
        this.$message.error("Bir hata oluştu");
      }

      this.$refs.file.value = null;
    }
  }
}
</script>

<style lang="less" scoped>
.personal-info {
  padding: 20px;

  .form-container {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
  }

  .left-section, .right-section {
    flex: 1;
  }

  .image-upload {
    margin-bottom: 30px;
    
    .image {
      cursor: pointer;
      width: 170px;
      height: 170px;
      background-position: center;
      background-size: cover;
      opacity: 0.8;
      border-radius: 40px;
      box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.7;
      }

      div, i {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
      }

      i {
        margin-bottom: 8px;
        font-size: 24px;
      }
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-row {
    display: flex;
    gap: 20px;

    .input-group {
      flex: 1;
    }
  }

  .input-group {
    &.full {
      width: 100%;
    }

    .text {
      font-size: 14px;
      color: #8697a8;
      margin-bottom: 8px;

      .required {
        color: #ff4949;
      }
    }
  }

  .full-width {
    width: 100%;
  }

  :deep(.el-input__inner) {
    background-color: #f8fafb;
    border: 1px solid #eaedf0;
    height: 40px;
    
    &:focus {
      border-color: #234a98;
    }
  }

  :deep(.el-textarea__inner) {
    background-color: #f8fafb;
    border: 1px solid #eaedf0;
    
    &:focus {
      border-color: #234a98;
    }
  }

  :deep(.vue-phone-number-input) {
    .select-country-container {
      background-color: #f8fafb;
      border: 1px solid #eaedf0;
    }

    .input-tel {
      background-color: #f8fafb;
      border: 1px solid #eaedf0;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .save-button {
      background-color: #234a98;
      border: none;
      padding: 12px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 8px;
      max-width: 200px;
      i {
        font-size: 16px;
      }

      &:hover {
        background-color: darken(#234a98, 5%);
      }
    }
  }
}
</style> 