<!-- PsychologistCalendar.vue -->
<template>
    <div class="calendar-container">
      <div class="calendar-header">
        <h2>Psikolog Randevu Takvimi</h2>
        <div class="date-navigation">
          <button @click="previousMonth">&#8592;</button>
          <span>{{ currentMonthYear }}</span>
          <button @click="nextMonth">&#8594;</button>
        </div>
      </div>
  
      <!-- Takvim -->
      <div class="calendar-grid">
        <div class="weekday-header" v-for="day in weekDays" :key="day">
          {{ day }}
        </div>
        <div
          v-for="(day, index) in calendarDays"
          :key="index"
          :class="['calendar-day', { 
            'working-day': isWorkingDay(day),
            'has-appointments': hasAppointments(day),
            'other-month': !isCurrentMonth(day)
          }]"
          @click="selectDay(day)"
        >
          <div class="day-header">
            <span class="day-number">{{ day.getDate() }}</span>
            <!-- Fazla randevular için popover -->
            <el-popover
              v-if="getDayAppointments(day).length > 4"
              placement="right"
              width="200"
              trigger="hover"
            >
              <div class="extra-appointments">
                <div 
                  v-for="appointment in getDayAppointments(day).slice(4)" 
                  :key="appointment.id"
                  class="appointment-indicator"
                >
                  {{ appointment.time }}
                </div>
              </div>
              <span 
                slot="reference" 
                class="more-appointments"
              >
                +{{ getDayAppointments(day).length - 4 }}
              </span>
            </el-popover>
          </div>
          <div class="appointments">
            <div 
              v-for="(appointment) in getDayAppointments(day).slice(0, 4)" 
              :key="appointment.id"
              class="appointment-indicator"
            >
              {{ appointment.time }}
            </div>
          </div>
        </div>
      </div>
  
      <!-- Randevu Detay Modal -->
      <div v-if="selectedDay" class="appointment-modal">
        <div class="modal-content">
          <h3>{{ formatDate(selectedDay) }} Randevuları</h3>
          <div class="available-slots">
            <div v-for="slot in getAvailableSlots(selectedDay)" :key="slot">
              <button 
                @click="createAppointment(slot)"
                :disabled="isSlotTaken(selectedDay, slot)"
              >
                {{ slot }}
              </button>
            </div>
          </div>
          <button @click="selectedDay = null">Kapat</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PsychologistCalendar',
    data() {
      return {
        currentDate: new Date(),
        selectedDay: null,
        weekDays: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
        appointments: [],
        settings: null // Doktor ayarlarını tutacak
      }
    },
    computed: {
      currentMonthYear() {
        return new Intl.DateTimeFormat('tr-TR', { 
          month: 'long', 
          year: 'numeric' 
        }).format(this.currentDate)
      },
      calendarDays() {
        const year = this.currentDate.getFullYear()
        const month = this.currentDate.getMonth()
        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0)
        
        let days = []
        
        // Önceki ayın günlerini ekle
        const firstDayOfWeek = firstDay.getDay() || 7
        for (let i = firstDayOfWeek - 1; i > 0; i--) {
          days.push(new Date(year, month, -i + 1))
        }
        
        // Mevcut ayın günlerini ekle
        for (let i = 1; i <= lastDay.getDate(); i++) {
          days.push(new Date(year, month, i))
        }
        
        // Sonraki ayın günlerini ekle
        const remainingDays = 42 - days.length
        for (let i = 1; i <= remainingDays; i++) {
          days.push(new Date(year, month + 1, i))
        }
        
        return days
      }
    },
    methods: {
      previousMonth() {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() - 1,
          1
        )
      },
      nextMonth() {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() + 1,
          1
        )
      },
      isCurrentMonth(date) {
        return date.getMonth() === this.currentDate.getMonth()
      },
      isWorkingDay(date) {
        if (!this.settings) return false

        // Off günlerini kontrol et
        const isOffDay = this.settings.offDays.some(offDay => {
          const offDate = new Date(offDay.date)
          return offDate.toDateString() === date.toDateString() && offDay.type === 'full'
        })

        if (isOffDay) return false

        // Haftanın gününü bul (0 = Pazar, 1 = Pazartesi)
        const dayIndex = date.getDay() || 7
        const adjustedIndex = dayIndex === 0 ? 6 : dayIndex - 1 // Pazartesi = 0 olacak şekilde ayarla
        
        return this.settings.workingDays[adjustedIndex].isWorking
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('tr-TR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }).format(date)
      },
      hasAppointments(day) {
        return this.appointments.some(apt => 
          apt.date.toDateString() === day.toDateString()
        )
      },
      getDayAppointments(day) {
        return this.appointments.filter(apt =>
          apt.date.toDateString() === day.toDateString()
        )
      },
      getAvailableSlots(day) {
        if (!this.settings || !this.isWorkingDay(day)) return []

        const dayIndex = day.getDay() || 7
        const adjustedIndex = dayIndex === 0 ? 6 : dayIndex - 1
        const daySettings = this.settings.workingDays[adjustedIndex]

        // Çalışma saatlerini dakikaya çevir
        const startMinutes = this.timeToMinutes(daySettings.startTime)
        const endMinutes = this.timeToMinutes(daySettings.endTime)
        
        const slots = []
        let currentMinutes = startMinutes

        // Özel izin saatlerini kontrol et
        const offTimeForDay = this.settings.offDays.find(offDay => {
          const offDate = new Date(offDay.date)
          return offDate.toDateString() === day.toDateString() && offDay.type === 'partial'
        })

        while (currentMinutes < endMinutes) {
          const currentTimeStr = this.minutesToTime(currentMinutes)
          
          // Öğle molası kontrolü
          if (daySettings.hasLunchBreak) {
            const lunchStart = this.timeToMinutes(daySettings.lunchBreakStart)
            const lunchEnd = this.timeToMinutes(daySettings.lunchBreakEnd)
            if (currentMinutes >= lunchStart && currentMinutes < lunchEnd) {
              currentMinutes = lunchEnd
              continue
            }
          }

          // Özel izin saatleri kontrolü
          if (offTimeForDay) {
            const offStart = this.timeToMinutes(offTimeForDay.startTime)
            const offEnd = this.timeToMinutes(offTimeForDay.endTime)
            if (currentMinutes >= offStart && currentMinutes < offEnd) {
              currentMinutes = offEnd
              continue
            }
          }

          slots.push(currentTimeStr)
          currentMinutes += this.settings.sessionDuration + this.settings.breakDuration
        }

        return slots
      },
      timeToMinutes(timeStr) {
        const [hours, minutes] = timeStr.split(':').map(Number)
        return hours * 60 + minutes
      },
      minutesToTime(minutes) {
        const hours = Math.floor(minutes / 60)
        const mins = minutes % 60
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
      },
      isSlotTaken(day, slot) {
        return this.appointments.some(apt =>
          apt.date.toDateString() === day.toDateString() && apt.time === slot
        )
      },
      createAppointment(slot) {
        if (!this.selectedDay) return
        
        const appointment = {
          id: Date.now(),
          date: this.selectedDay,
          time: slot,
          patientName: '' // Bu kısım daha sonra randevu oluşturma formundan doldurulabilir
        }
        
        this.appointments.push(appointment)
        this.$emit('appointment-created', appointment)
      },
      selectDay(day) {
        this.selectedDay = day
      }
    },
    created() {
      // Store'dan doktor ayarlarını al
      this.settings = this.$store.getters.doctorSettings
      
      // Store'daki ayarları dinle
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'updateDoctorSettings') {
          this.settings = state.doctorSettings
        }
      })
    }
  }
  </script>
  
  <style scoped>
  .calendar-container {
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .date-navigation {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-top: 20px;
  }
  
  .weekday-header {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    background-color: #f0f0f0;
  }
  
  .calendar-day {
    border: 1px solid #ddd;
    height: 130px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    overflow: visible;
  }
  
  .day-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 2px 5px;
  }
  
  .day-number {
    font-weight: 500;
  }
  
  .working-day {
    background-color: #e8f5e9;
  }
  
  .has-appointments {
    background-color: #e3f2fd;
  }
  
  .other-month {
    opacity: 0.5;
  }
  
  .appointments {
    margin-top: 5px;
    font-size: 0.8em;
    max-height: 100px;
    overflow-y: auto;
    scrollbar-width: thin;
    -ms-overflow-style: none;
  }
  
  .appointment-indicator {
    background-color: #2196f3;
    color: white;
    padding: 2px 4px;
    margin: 2px;
    border-radius: 3px;
    white-space: nowrap;
  }
  
  .more-appointments {
    font-size: 0.8em;
    color: #2196f3;
    background-color: #e3f2fd;
    padding: 2px 6px;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .more-appointments:hover {
    background-color: #bbdefb;
  }
  
  .extra-appointments {
    max-height: 200px;
    overflow-y: auto;
    padding: 5px;
  }
  
  /* Scrollbar styles */
  .appointments::-webkit-scrollbar,
  .extra-appointments::-webkit-scrollbar {
    width: 4px;
  }
  
  .appointments::-webkit-scrollbar-track,
  .extra-appointments::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
  }
  
  .appointments::-webkit-scrollbar-thumb,
  .extra-appointments::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }
  
  .appointments::-webkit-scrollbar-thumb:hover,
  .extra-appointments::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Element UI Popover özelleştirmesi */
  :deep(.el-popover) {
    padding: 8px;
    min-width: 150px;
  }
  
  .appointment-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }
  
  .available-slots {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
    margin: 20px 0;
  }
  
  .available-slots button {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }
  
  .available-slots button:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  </style>