<template>
  <div class="doctor-screen">
    <div class="tabs">
      <div class="tab-buttons">
        <button 
          v-for="(tab, index) in tabs" 
          :key="index"
          :class="{ active: currentTab === index }"
          @click="currentTab = index"
        >
          {{ tab.name }}
        </button>
      </div>

      <div class="tab-content">
        <div v-if="currentTab === 0">
          <PersonalInformation 
            :doctor="doctor"
            :specialities="specialities"
            @save="saveDoctor"
            @phone-update="updatePhone" />
        </div>
        <div v-if="currentTab === 1">
          <AddressInformation 
            :doctor="doctor"
            @save="saveDoctor" />
        </div>
        <div v-if="currentTab === 2">
          <DoctorScheduleSettings />
        </div>
        <div v-if="currentTab === 3">
          <PsychologistCalendar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalInformation from './components/PersonalInformation.vue';
import AddressInformation from './components/AddressInformation.vue';
import DoctorScheduleSettings from './components/DoctorScheduleSettings.vue';
import PsychologistCalendar from './components/Calendar.vue';

export default {
  name: 'DoctorScreen',
  components: {
    PersonalInformation,
    AddressInformation,
    DoctorScheduleSettings,
    PsychologistCalendar
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        { name: 'Kişisel Bilgiler' },
        { name: 'Adres Bilgileri' },
        { name: 'Çalışma Saatleri' },
        { name: 'Randevular' }
      ],
      specialities: [],
      phoneFormatter: null
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.doctor;
    }
  },
  async created() {
    await this.getSpecialities();
  },
  mounted() {
    this.getDoctor();
  },
  methods: {
    async getDoctor() {
      let id = this.$route.params.id;
      await this.$store.dispatch('getDoctor', id);
    },
    async getSpecialities() {
      const res = await this.$client.post("/Speciality/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.specialities = res.data.Data.Items.filter(x => x.IsActive);
    },
    updatePhone(phoneData) {
      this.phoneFormatter = phoneData;
    },

    async saveDoctor() {
      if (this.currentTab === 0 && this.phoneFormatter) {
        this.doctor.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
      }

      try {
        const res = await this.$client.post("/Doctor/Update", this.doctor);
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success("Doktor bilgileri başarıyla güncellendi");
          await this.getDoctor();
        }
      } catch (error) {
        this.$message.error("Bir hata oluştu");
      }
    }
  }
}
</script>

<style scoped>
.doctor-screen {
  padding: 20px;
}

.tabs {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
}

.tab-buttons button.active {
  border-bottom: 2px solid #2196F3;
  color: #2196F3;
}

.tab-content {
  padding: 20px;
}
</style>
